<template>
    <div class="box">
        <van-tabbar v-model="$store.state.tabbarIndex" active-color="#007AFF" @change="handleTabbarChange">
            <template v-for="(config, index) in tabbarConfig">
                <van-tabbar-item v-show="config.show" @click="jumpto(config.url)" :key="index">
                    <span>{{ config.label }}</span>
                    <template #icon="props">
                        <img :src="props.active ? config.activeImg : config.inactiveImg" />
                    </template>
                </van-tabbar-item>
            </template>
        </van-tabbar>
    </div>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
export default {
    data() {
        return {
            active: 0,
            tabbarConfig: [
                {
                    label: '首页',
                    activeImg: require('./../assets/image/tab01.png'),
                    inactiveImg: require('./../assets/image/tab00.png'),
                    url: '/sy',
                    show: true
                },
                {
                    label: '审核',
                    activeImg: require('./../assets/image/tab11.png'),
                    inactiveImg: require('./../assets/image/tab10.png'),
                    url: '/sh',
                    show: true
                },
                {
                    label: '巡查',
                    activeImg: require('./../assets/image/tab21.png'),
                    inactiveImg: require('./../assets/image/tab20.png'),
                    url: '/xc',
                    show: true
                },
                {
                    label: '施工',
                    activeImg: require('./../assets/image/tab31.png'),
                    inactiveImg: require('./../assets/image/tab30.png'),
                    url: '/sg',
                    show: true
                },
                {
                    label: '测试',
                    url: '/ts',
                    show: false
                }
            ]
        }
    },
    computed: mapState(['tabbarIndex']),
    watch: {
        active(index) {
            this.$store.state.tabbarIndex = index
        }
    },
    created() {
        // 如果是指定账号则可以看到测试
        const { dd_user_id, is_faked } = JSON.parse(sessionStorage.getItem('userInfo'))
        if (dd_user_id === 44 || is_faked) {
            this.tabbarConfig.find((tabbar) => tabbar.label === '测试').show = true
        }
        // if (userInfo.identity !== this.IDENTITY_MATCHER) {
        //     this.tabbarConfig.find((config) => config.label === '审核').show = true
        // } else if (userInfo.identity === this.IDENTITY_MATCHER) {
        //     this.tabbarConfig.find((config) => config.label === '巡查').show = true
        // }
    },
    mounted() {},
    methods: {
        handleTabbarChange() {}
    }
}
</script>
<style lang="less" scoped>
.van-tabbar {
    height: 60px;
    // padding-bottom: 15px;
}
</style>
