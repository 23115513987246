<template>
    <div id="app">
        <router-view />
        <Tab v-if="show" />
    </div>
</template>
<script>
import Tab from '@/components/Tab.vue'
export default {
    name: '',
    components: {
        Tab
    },
    data() {
        // var _this = this;
        return {
            show: false
        }
    },
    watch: {
        $route(to) {
            if (to.path === '/sy') {
                this.show = true
            } else if (to.path === '/sh') {
                this.show = true
            } else if (to.path.indexOf('/xc') !== -1) {
                this.show = true
            } else if (to.path.indexOf('/sg') !== -1) {
                this.show = true
            } else {
                this.show = false
            }
        }
    },
    created() {},
    mounted() {},
    methods: {}
}
</script>
<style>
@import 'assets/css/common.css';
@import 'assets/font/font.css';
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
</style>
