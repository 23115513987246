export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login')
    },
    {
        path: '/',
        name: 'rk',
        component: () => import('../views/dlsgjgpt/rk/rk'),
        meta: {
            title: '入口',
            tabbarGroup: 'rk'
        }
    },
    {
        path: '/sy',
        name: 'sy',
        component: () => import('../views/dlsgjgpt/sy/sy'),
        meta: {
            title: '首页',
            tabbarGroup: 'sy'
        }
    },
    {
        path: '/sh',
        name: 'sh',
        component: () => import('../views/dlsgjgpt/sh/sh'),
        meta: {
            title: '审核',
            tabbarGroup: 'sh'
        }
    },
    {
        path: '/shxx',
        name: 'shxx',
        component: () => import('../views/dlsgjgpt/sh/shxx')
    },
    {
        path: '/xc',
        name: 'xc',
        component: () => import('../views/dlsgjgpt/xc/xc'),
        meta: {
            title: '巡查',
            tabbarGroup: 'xc'
        }
    },
    {
        path: '/sg',
        name: 'sg',
        component: () => import('../views/dlsgjgpt/sg/sg'),
        meta: {
            title: '施工',
            tabbarGroup: 'sg'
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/dlsgjgpt/sg/detail')
    },
    {
        path: '/form',
        name: 'form',
        component: () => import('../views/dlsgjgpt/sg/form')
    },
    {
        path: '/ts',
        name: 'ts',
        component: () => import('../views/dlsgjgpt/ts/ts')
    }
].map((page, index) => ({
    path: page.path,
    name: page.name,
    component: page.component,
    meta: Object.assign({ pageId: index }, page.meta)
}))
