import Router from 'vue-router'
import Vue from 'vue'
import system from './system'
import { Toast } from 'vant'
import dd from 'gdt-jsapi'
Vue.use(Router)

const router = new Router({
    base: process.env.NODE_ENV === 'production' ? '/' : '/',
    routes: system,
    scrollBehavior(to, from, saveTop) {
        if (saveTop) {
            return saveTop
        } else {
            return { x: 0, y: 0 }
        }
    }
})

const loadingToast = Toast.loading({
    overlay: true,
    message: '加载中...'
})

router.beforeEach((to, from, next) => {
    loadingToast.open()
    // console.log(from.path + ' route to ' + to.path)
    if (to.path === from.path) {
        next(false)
    }
    if (to.path === '/' && from.path === '/shxx') {
        next({ name: 'sh' })
    }
    if (to.path === '/' && from.path === '/detail') {
        next({ name: 'sg' })
    }
    if (to.path === '/' && from.path === '/sy') {
        if (process.env.NODE_ENV === 'production') {
            dd.closePage().then((res) => {
                console.log(res)
            })
        }
    }
    next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    if (process.env.NODE_ENV === 'production') {
        if (to.path !== '/') {
            const { account_id } = JSON.parse(sessionStorage.getItem('userInfo'))
            if (account_id !== null && account_id !== undefined) {
                //用户信息埋点
                // 如采集用户信息是异步行为需要先执行这个BLOCK埋点
                window.aplus_queue.push({
                    action: 'aplus.setMetaInfo',
                    arguments: ['_hold', 'BLOCK']
                })

                //基础埋点
                // 单页应用 或 “单个页面”需异步补充PV日志参数还需进行如下埋点：
                window.aplus_queue.push({
                    action: 'aplus.setMetaInfo',
                    arguments: ['aplus-waiting', 'MAN']
                })
                // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
                window.aplus_queue.push({
                    action: 'aplus.sendPV',
                    arguments: [
                        {
                            is_auto: false
                        },
                        {
                            // 当前你的应用信息，此两行请勿修改
                            sapp_id: '23144',
                            sapp_name: 'ra_rcsp',
                            // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
                            page_id: to.meta.pageId,
                            page_name: '交警道路挖掘施工监管',
                            page_url: window.location.href
                        }
                    ]
                })

                // 设置会员ID
                window.aplus_queue.push({
                    action: 'aplus.setMetaInfo',
                    arguments: ['_user_id', account_id.toString()]
                })

                // 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
                // 此时被block住的日志会携带上用户信息逐条发出
                window.aplus_queue.push({
                    action: 'aplus.setMetaInfo',
                    arguments: ['_hold', 'START']
                })
            }
        }
    }

    loadingToast.clear()
})
export default router
